import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useRouter from 'use-react-router';
import classNames from 'classnames';
import { useLights } from 'Lights';
import { Auftauchen } from 'Auftauchen';

const useStylesItem = makeStyles({
    link: {
        textDecoration: 'none',
        '&$current, &:hover, &:active': {
            '& $item': {
                opacity: 1,
            },
        },
        '&$current': {
            '& $item::after': {
                opacity: 1,
            },
        },
    },
    item: {
        position: 'relative',

        opacity: 0.5,
        transition: 'opacity 150ms linear',

        '&::after': {
            content: '\'\'',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: -8,
            height: 4,
            backgroundColor: '#FFFFFF',
            opacity: 0,
        },

        width: 80,
        height: 24,
        color: '#FFFFFF',
        fontSize: 18,
        lineHeight: '24px',
        margin: '20px 0',
        letterSpacing: 0,
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '&$lightsOn': {
            color: '#004FC5',
            '&::after': {
                backgroundColor: '#004FC5',
            },
        },
    },
    current: {},
    lightsOn: {},
});

function Item({ to, match, children }) {
    const classes = useStylesItem();
    const { location: { pathname } } = useRouter();
    const current = useMemo(() => match.some(one => one === pathname), [match, pathname]);
    const { lights } = useLights();
    return (
        <Link to={to} className={classNames(classes.link, { [classes.current]: current })}>
            <Typography classes={{ root: classNames(classes.item, { [classes.lightsOn]: lights === 'on' }) }}>
                {children.split('').map((c, n) => (
                    <span key={n}>{c}</span>
                ))}
            </Typography>
        </Link>
    );
}

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '20%',
        bottom: '30%',
        left: 42,
        zIndex: 2,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
});

function Nav() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Auftauchen i={2} className={classes.container}>
                <Item to="/products" match={['/', '/products']}>产品</Item>
                <Item to="/sair" match={['/sair']}>S A I R</Item>
                <Item to="/timeline" match={['/timeline']}>时间线</Item>
                <Item to="/about" match={['/about']}>关于我们</Item>
                <Item to="/joinus" match={['/joinus']}>加入我们</Item>
            </Auftauchen>
        </div>
    );
}

export default Nav;
