const responsiveFont1 = (n = 1) => ({
    fontSize: 16 * n,
    '@media(min-width: 1920px)': {
        fontSize: 18 * n,
    },
    '@media(max-width: 1439px)': {
        fontSize: 14 * n,
    },
    '@media(max-width: 1079px)': {
        fontSize: 12 * n,
    },
});

export { responsiveFont1 };
