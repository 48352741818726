import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import zkj from 'assets/images/zkj.jpg';
import zkj2x from 'assets/images/zkj@2x.jpg';
//import ry from 'assets/images/ry.jpg';
//import ry2x from 'assets/images/ry@2x.jpg';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';

const useStyles = makeStyles({
    content: {
        marginTop: 113, // alignment, not em-based
    },
    title: {
        fontSize: '3.4375em',
        lineHeight: 1.27,
    },
    subtitle: {
        fontSize: '2.125em',
        fontWeight: 600,
        letterSpacing: 0,
        marginTop: '0.4em',
    },
    desc: {
        fontSize: '0.8125em',
        fontWeight: 'normal',
        letterSpacing: 0.14,
        textAlign: 'justify',
        width: '57.84em',
        marginTop: '2em',
        lineHeight: 1.6,
    },
    footer: {
        position: 'absolute',
        left: 54, // aligned to footer, not em-based, for the whole footer div likewise
        bottom: 54,
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        width: '5.5625em',
        height: '5.5625em',
        objectFit: 'cover',
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 24,
    },
    profile: {
        marginRight: 54,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    name: {
        fontSize: '1.3125em',
        lineHeight: 1.4,
        fontWeight: 600,
    },
    info: {
        fontSize: '0.8125em',
        lineHeight: 1.6,
        fontWeight: 'normal',
    },
});

function SAIR() {
    const classes = useStyles();
    return (
        <Auftauchung><Page>
            <div className={classes.content}>
                <Auftauchen i={0}><div className={classes.title}>ZJU Next Lab x<br/>StepBeats AI Research</div></Auftauchen>
                <Auftauchen i={1}><Typography classes={{ root: classes.subtitle }} color="inherit">浙江大学 Next Lab x 不亦乐乎人工智能联合实验室</Typography></Auftauchen>
                <Auftauchen i={2}><Typography classes={{ root: classes.desc }} color="inherit">
	    	    ZJU Next Lab x Stepbeats AI Research 负责不亦乐乎公司核心技术的研发、论文发表和专利申请工作，并探索潜在的业务场景。SAIR的研究领域涉及音乐合成、歌声合成、语音合成、音乐信息检索等各个领域。部门成员由公司成员和浙大联合实验室成员共同组成，在国内外顶级会议如 IJCAI、AAAI 及 ACM MM 等，以及国内外知名期刊上发表论文十余篇，相关研究获得发明专利、实⽤新型专利及软件著作权十余项。
                </Typography></Auftauchen>
            </div>
            <div className={classes.footer}>
                <Auftauchen i={3}><img className={classes.avatar} srcSet={`${zkj} 1x, ${zkj2x} 2x`} alt="Avatar"/></Auftauchen>
                <Auftauchen i={3}><div className={classes.profile}>
                    <Typography classes={{ root: classes.name }} color="inherit">张克俊</Typography>
                    <Typography classes={{ root: classes.info }} color="inherit">
                        浙江大学计算机学院 副教授，博士生导师<br/>
                        浙江大学科技设计重点实验室 Next Lab 负责人
                    </Typography>
                </div></Auftauchen>
                {/*<Auftauchen i={3}><img className={classes.avatar} srcSet={`${ry} 1x, ${ry2x} 2x`} alt="Avatar"/></Auftauchen>
                <Auftauchen i={3}><div className={classes.profile}>
                    <Typography classes={{ root: classes.name }} color="inherit">任意</Typography>
                    <Typography classes={{ root: classes.info }} color="inherit">
                        不亦乐乎科技 SAIR 负责人<br/>
                        浙江大学计算机学院研究生
                    </Typography>
                </div></Auftauchen>*/}
            </div>
        </Page></Auftauchung>
    );
}

export default SAIR;
