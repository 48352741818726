import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import wechat from 'assets/icons/wechat.svg';
import weibo from 'assets/icons/weibo.svg';
import twitter from 'assets/icons/twitter.svg';
import facebook from 'assets/icons/facebook.svg';
import wechatDark from 'assets/icons/wechat-dark.svg';
import weiboDark from 'assets/icons/weibo-dark.svg';
import twitterDark from 'assets/icons/twitter-dark.svg';
import facebookDark from 'assets/icons/facebook-dark.svg';
import wechatQr from 'assets/images/wechat-qr.svg';
import { useLights } from 'Lights';
import classNames from 'classnames';
import { Auftauchen } from 'Auftauchen';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: 36,
        right: 54,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 2,
    },
    icon: {
        height: 34,
        marginLeft: 21,
        cursor: 'pointer',
        transform: 'none',
        transition: 'transform 200ms cubic-bezier(0, 0.4, 0.2, 1)',
        '&:hover, &:active': {
            transform: 'translateY(-10px)',
        },
        color: '#FFFFFF',
    },
    popupContainer: {
        display: 'flex',
        position: 'relative',
    },
    qr: {
        position: 'absolute',
        top: 34,
        right: 0,
        opacity: 0,
        '&$visible': {
            opacity: 1,
        },
        transition: 'opacity 150ms linear',
    },
    visible: {},
    link: {
        display: 'flex',
    },
});

function Contacts() {
    const classes = useStyles();
    const { lights } = useLights();
    const [hovered, setHovered] = useState(false);
    const onMouseEnter = useCallback(() => setHovered(true), []);
    const onMouseLeave = useCallback(() => setHovered(false), []);
    return (
        <Auftauchen i={1} className={classes.root}>
            <div className={classes.popupContainer}>
                <img
                    className={classes.icon}
                    alt="EnjoyMusic Wechat Account"
                    src={{ off: wechat, on: wechatDark, half: wechat, redHalf: wechat }[lights]}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}/>
                <img className={classNames(classes.qr, { [classes.visible]: hovered })} src={wechatQr} alt=""/>
            </div>
            <a href="https://weibo.com/u/6514486719" className={classes.link} target="_blank" rel="noopener noreferrer">
                <img className={classes.icon} alt="EnjoyMusic Weibo Account" src={{ off: weibo, on: weiboDark, half: weibo, redHalf: weibo }[lights]}/>
            </a>
            <a href="https://twitter.com/EnjoyMusic19" className={classes.link} target="_blank" rel="noopener noreferrer">
                <img className={classes.icon} alt="EnjoyMusic Twitter Account"
                     src={{ off: twitter, on: twitterDark, half: twitter, redHalf: twitter }[lights]}/>
            </a>
            <a href="https://fb.me/EnjoyMusicTech" className={classes.link} target="_blank" rel="noopener noreferrer">
                <img className={classes.icon} alt="EnjoyMusic Facebook Account"
                     src={{ off: facebook, on: facebookDark, half: facebook, redHalf: facebook }[lights]}/>
            </a>
        </Auftauchen>
    );
}

export default Contacts;
