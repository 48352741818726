import { useCallback, useMemo, useState } from 'react';

const useStateNonFunctional = (...args) => {
    const [state, setState] = useState(...args);
    const setStateWrapped = useCallback((newState) => setState(() => newState), []);
    return [state, setStateWrapped];
};

const usePrompt = ({ openDialog, closeDialog }) => {
    const [promptPromise, setPromptPromise] = useState(null);
    const [resolve, setResolve] = useStateNonFunctional(null);
    const [reject, setReject] = useStateNonFunctional(null);
    const ask = useCallback(() => {
        if (promptPromise != null) return promptPromise;
        const promise = new Promise((resolve, reject) => {
            setResolve(resolve);
            setReject(reject);
            openDialog();
        });
        setPromptPromise(promise);
        return promise;
    }, [promptPromise, setResolve, setReject, openDialog]);
    const endPrompt = useCallback(() => {
        setReject(null);
        setResolve(null);
        setPromptPromise(null);
        closeDialog();
    }, [closeDialog, setReject, setResolve]);
    const answer = useCallback((ans) => {
        if (resolve) resolve(ans);
        endPrompt();
    }, [resolve, endPrompt]);
    const cancel = useCallback(() => {
        if (resolve) resolve(false);
        endPrompt();
    }, [endPrompt, resolve]);
    const croak = useCallback((err) => {
        if (reject) reject(err);
        endPrompt();
    }, [reject, endPrompt]);
    return useMemo(() => ({ ask, answer, cancel, croak }), [cancel, croak, answer, ask]);
};

export default usePrompt;
