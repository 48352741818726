import React, { useContext, useMemo, useState } from 'react';

const LightsContext = React.createContext(null);

function Lights({ children }) {
    const [lights, setLights] = useState('off');
    const value = useMemo(() => ({ setLights, lights }), [lights]);
    return (
        <LightsContext.Provider value={value}>
            {children}
        </LightsContext.Provider>
    );
}

const useLights = () => useContext(LightsContext);

export { useLights, Lights };
