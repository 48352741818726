import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

const DialogContext = React.createContext({});

const useDialog = () => {
    const [opened, setOpened] = useState(false);
    const openDialog = useCallback(() => setOpened(true), [setOpened]);
    const closeDialog = useCallback(() => setOpened(false), [setOpened]);
    return { opened, openDialog, closeDialog};
};

const useDialogShowed = () => {
    const [opened, setOpened] = useState(true);
    const openDialog = useCallback(() => setOpened(true), [setOpened]);
    const closeDialog = useCallback(() => setOpened(false), [setOpened]);
    return { opened, openDialog, closeDialog};
};

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    closed: {
        pointerEvents: 'none',
    },
});

function DialogContent(props) {
    // noinspection JSUnusedLocalSymbols
    const { dialog, children, rootProps = {}, TransitionComponent = CSSTransition, classes: _, ...rest } = props;
    const { opened } = dialog;
    const classes = useStyles(props);
    return (
        <DialogContext.Provider value={dialog}>
            <div className={classNames(classes.root, { [classes.closed]: !opened })} {...rootProps}>
                <TransitionComponent in={opened} {...rest}>
                    {children}
                </TransitionComponent>
            </div>
        </DialogContext.Provider>
    );
}

export { useDialog, DialogContext, DialogContent, useDialogShowed };
