/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';
import ball1 from 'assets/images/ball1.png';
import ball2 from 'assets/images/ball2.png';
import ball3 from 'assets/images/ball3.png';
import title1 from 'assets/images/wanyin-title1.svg';
import title2 from 'assets/images/wanyin-title2.svg';
import appStore from 'assets/images/app_store.svg';
import appStore2x from 'assets/images/app_store.svg';
import androidStore from 'assets/images/android_store.svg';
import androidStore2x from 'assets/images/android_store.svg';
import wanyinApp from 'assets/images/wanyin-app.png';
import wanyinApp2x from 'assets/images/wanyin-app@2x.png';
import circleClose from 'assets/icons/circleClose-light.svg';

import { responsiveFont1 } from 'utils/styles';
import { DialogContent, useDialog } from 'component/Dialog/Dialog';
import usePrompt from 'component/Dialog/Prompt';
import { CSSTransition } from 'react-transition-group';
// eslint-disable-next-line import/no-webpack-loader-syntax
import terms from '!html-loader!markdown-loader!assets/misc/terms.md';
// eslint-disable-next-line import/no-webpack-loader-syntax
import terms2 from '!html-loader!markdown-loader!assets/misc/terms2.md';

const useStylesTerms = makeStyles({
    root: {
        ...responsiveFont1(),
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: '10.25em 0 4.5em',
    },
    content: {
        width: '72.8em',
        backgroundColor: '#FFFFFF',
        position: 'relative',
    },
    title: {
        position: 'absolute',
        left: '1.125em',
        top: '0.9375em',
        fontSize: '2em',
        lineHeight: 2.25,
        fontWeight: 600,
        color: '#000000',
    },
    text: {
        position: 'absolute',
        left: '2.25em',
        top: '6.8em',
        right: '2.25em',
        bottom: '6.8em',
        backgroundColor: '#F7F7F7',
        color: '#9B9B9B',
        padding: '2.2em 1.44em',
        overflow: 'auto',
    },
    button1: {
        position: 'absolute',
        bottom: '1.8em',
        right: '11.7em',
    },
    button2: {
        position: 'absolute',
        bottom: '1.8em',
        right: '2.25em',
        backgroundColor: '#F46173',
        color: '#FFFFFF',
    },
    circleClose: {
        position: 'absolute',
        width: 20,
        height: 20,
        top: '1em',
        right: '1em',
        cursor: 'pointer'
    }
});

const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        transform: 'scaleY(-1)',
        background: 'linear-gradient(180deg, #FFD4C7 0%, #F03A3A 100%)',
        position: 'relative',
        overflow: 'hidden'
    },
    title1: {
        position: 'absolute',
        left: '1.1765em',
        top: 'calc(-100px + 41.64%)',
        fontSize: '4.25em',
        lineHeight: 1.27,
        '@media (max-width: 1080px)': {
            width: '14em',
            left: '4.85em',
            top: '10.26em',
            fontSize: 14
        },
    },
    title2: {
        position: 'absolute',
        left: '1.1765em',
        top: 'calc(-100px + 54.64%)',
        fontSize: '4.25em',
        lineHeight: 1.27,
        '@media (max-width: 1080px)': {
            width: '26.11em',
            height: '5.22em',
            left: '4.85em',
            top: '22.01em',
            fontSize: 14
        },
    },
    desc: {
        position: 'absolute',
        left: '6.154em',
        top: 'calc(-100px + 45.64% + 13.7em)',
        width: '41em',
        fontSize: '0.8125em',
        fontWeight: 'normal',
        letterSpacing: 0.14,
        textAlign: 'justify',
        lineHeight: 1.6,
        '@media (max-width: 1080px)': {
            left: '2.89em',
            top: '18.875em',
            width: '33.31em',
            fontSize: 24,
            WebkitTextSizeAdjust: 'none',
        },
    },
    pages: {
        display: 'block',
        paddingBottom: 0,
        height: '100%',
        paddingTop: 120,
    },
    ball1: {
        position: 'absolute',
        left: '1em',
        top: '13.05%',
        width: '25em',
        '@media (max-width: 1080px)': {
            left: '-1.12em',
            top: '-2.98em',
            width: '42.15em',
        },
    },
    ball2: {
        position: 'absolute',
        left: '33em',
        top: '63.05%',
        width: '19.5em',
        '@media (max-width: 1080px)': {
            left: '32.08em',
            top: '34.32em',
            width: '37.49em',
        },
    },
    ball3: {
        position: 'absolute',
        left: '71em',
        top: '26.05%',
        width: '17.5em',
        '@media (max-width: 1080px)': {
            left: '10.82em',
            top: '56.51em',
            width: '37.67em',
        },
    },
    downloads: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        left: '5em',
        top: '72.18%',
        '@media (max-width: 1080px)': {
            left: '4.85em',
            top: '44.58em',
        },
    },
    badge: {
        display: 'block',
        marginRight: 20,
        height: 40,
        cursor: 'pointer',
        '@media (max-width: 1080px)': {
            width: '13.43em',
            height: '4.66em',
            marginRight: '2.98em'
        },
    },
    disabled: {
        cursor: 'not-allowed',
    },
    links: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        left: '5em',
        color: '#FFFFFF',
        bottom: '5.13%',
        '@media (max-width: 1080px)': {
            left: '4.85em',
            top: '52.22em',
            bottom: 'auto'
        },
    },
    link: {
        fontSize: '0.8125em',
        fontWeight: 'normal',
        letterSpacing: 1,
        lineHeight: 1.85,
        '@media (max-width: 1080px)': {
            fontSize: 24,
            WebkitTextSizeAdjust: 'none',
        },
    },
    a: {
        color: 'inherit',
        display: 'block',
        marginRight: '2em',
    },
    app: {
        position: 'absolute',
        right: '-9.625em',
        top: '9em',
        bottom: 0,
        height: 'calc(100% - -18.4375em)',
        '@media (max-width: 1080px)': {
            width: '90.32em',
            height: '105.03em',
            left: '-12.7em',
            top: '67.7em'
        },
    },
    container: {
        paddingTop: 140,
        paddingLeft: 120,
        paddingRight: 120,
        paddingBottom: 144,
        zIndex: 103, // over Step1 itself
        [theme.breakpoints.down('md')]: {
            paddingLeft: 60,
            paddingRight: 60,
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 80,
            paddingBottom: 0,
        },
    },
    overlay: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 100,
    },
}));

function SAIR() {
    const classes = useStyles();
    const termsClasses = useStylesTerms();
    const dialog = useDialog();
    const { ask, cancel } = usePrompt(dialog);
    const [state, setState] = useState(1);
    const openDialog = useCallback(async (type) => {
        setState(type);
        const ret = await ask();
        return ret;
    }, [ask]);
    const bgClick = useCallback(({ target, currentTarget }) => {
        if (target === currentTarget) cancel();
    }, [cancel]);
    const closeDialog = useCallback(() => {
        cancel();
    }, [cancel]);
    return (
        <Auftauchung><Page wide classes={{ pages: classes.pages }}>
            <Auftauchen i={5} className={classes.content}>
                <img src={ball1} className={classes.ball1} alt=""/>
                <img src={ball2} className={classes.ball2} alt=""/>
                <img src={ball3} className={classes.ball3} alt=""/>
                <img src={title1} className={classes.title1} alt=""/>
                <img src={title2} className={classes.title2} alt=""/>
                <Typography classes={{ root: classes.desc }} color="inherit">
                    丸音 APP 是一款基于 AI 音乐创作的音乐分享社区，<br></br>整合了音乐滤镜，伴奏分离，人声美化，语音去噪等有趣的音乐玩法。<br></br>我们希望通过丸音， 让更多喜欢音乐的人能轻松地进行音乐创作。<br/>欢迎加群交流：分享群855275770 / 创作群703213277
                </Typography>
                <div className={classes.downloads}>
                <a
                        href="https://apps.apple.com/cn/app/丸音-音乐超好玩/id1530505960?l=en"
                        // target="_blank"
                        rel="noopener noreferrer"
                        className={classes.badge}
                        >
                    <img 
                        className={classes.badge} 
                        srcSet={`${appStore} 1x, ${appStore2x} 2x`} 
                        alt="Download on App Store"/>
                        </a>
                    <a
                        href="https://get.wanyinapp.com/wanyinapp.apk"
                        // target="_blank"
                        rel="noopener noreferrer"
                        className={classes.badge}
                        download>
                        <img 
                            className={classes.badge}  
                            srcSet={`${androidStore} 1x, ${androidStore2x} 2x`} 
                            alt="Download on Android Store"/>
                    </a>
                </div>
                <div className={classes.links}>
                    <a href="#" className={classes.a}>
                        <Typography classes={{ root: classes.link }} onClick={() => openDialog(1)}>服务条款</Typography>
                    </a>
                    <a href="#" className={classes.a}>
                        <Typography classes={{ root: classes.link }} onClick={() => openDialog(2)}>隐私政策</Typography>
                    </a>
                </div>
                <img srcSet={`${wanyinApp} 1x, ${wanyinApp2x} 2x`} className={classes.app} alt=""/>
            </Auftauchen>
            <CSSTransition in={dialog.opened} timeout={350} classNames="fade" unmountOnExit>
                <div className={classes.overlay}/>
            </CSSTransition>
            <DialogContent
                dialog={dialog}
                timeout={350}
                classNames="emerge"
                unmountOnExit
                classes={{ root: classes.container }}>
                <div className={termsClasses.root} onClick={bgClick}>
                    <div className={termsClasses.content}>
                        <Typography classes={{ h3: termsClasses.title }} variant="h3">
                            丸音APP {state === 1 ? '用户服务协议' : '隐私权政策'}
                        </Typography>
                        <img 
                            src={circleClose} 
                            className={termsClasses.circleClose} 
                            alt="circleClose"
                            onClick={closeDialog} />
                        <div 
                            className={termsClasses.text} 
                            dangerouslySetInnerHTML={{ __html: state === 1 ? terms : terms2 }}/>
                    </div>
                </div>
            </DialogContent>
        </Page></Auftauchung>
    );
}

export default SAIR;
