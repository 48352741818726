import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useLights } from 'Lights';
import classNames from 'classnames';

const useStyles = makeStyles({
    container: {
        position: 'relative',
        maxWidth: 1440,
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
        position: 'absolute',
        width: '80%',
        top: 0,
        right: 0,
        bottom: 0,

        fontFamily: 'HansKendrick',
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: 0,

        color: '#FFFFFF',
        '&$lightsOn': {
            color: '#004FC5',
        },

        fontSize: 16,
        '@media (max-width: 1199px) and (min-width: 960px)': {
            fontSize: 14,
        },
        '@media (max-width: 959px)': {
            fontSize: 12,
        },

        '&$wide': {
            width: '100%',
        },
    },
    scroller: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
    },
    pages: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'justify-items',
        paddingTop: 'calc(45vh - 263px)',
        paddingBottom: 40,
        paddingRight: 54,
        paddingLeft: 54,
    },
    lightsOn: {},
    illustration: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '51%',
    },
    emContainer: {
        position: 'fixed',
        right: 0,
        top: 0,
        width: '70vw',
        height: '100vh',
    },
    em: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    wide: {},
});

function Page({ children, scrollerRef, wide, ...props }) {
    const classes = useStyles(props);
    const { lights } = useLights();
    return (
        <div className={classes.scroller} ref={scrollerRef}>
            <div className={classes.container}>
                <div className={classNames(classes.root, {
                    [classes.lightsOn]: lights === 'on',
                    [classes.wide]: wide,
                })}>
                    <div className={classes.pages}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page;
