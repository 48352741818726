import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import back from 'assets/images/back.jpg';
import back2x from 'assets/images/back@2x.jpg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import backTiny from '!!url-loader!assets/images/back-tiny.png'
import dialog from 'assets/images/dialog.svg';
import _3asic from 'assets/images/Basic.png';
import _3asic2x from 'assets/images/Basic@2x.png';
import ellipsis from 'assets/icons/ellipsis.svg';
import hcz from 'assets/images/侯晨钟.png';
import hcz2x from 'assets/images/侯晨钟@2x.png';
import wfr from 'assets/images/吴斐然.png';
import wfr2x from 'assets/images/吴斐然@2x.png';
import wxy from 'assets/images/王小毅.png';
import wxy2x from 'assets/images/王小毅@2x.png';
import tx from 'assets/images/谭旭.png';
import tx2x from 'assets/images/谭旭@2x.png';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';
import Bild from 'Bild';

const useStyles = makeStyles({
    title: {
        fontSize: '3.4375em',
    },
    subtitle: {
        fontSize: '2.125em',
        marginBottom: '1em',
        letterSpacing: 1,
    },
    photo: {
        position: 'relative',
        height: '36.375em',
        zIndex: 3,
    },
    dialog: {
        position: 'absolute',
        right: 'calc(70% - 290px)',
        top: '43%',
        height: '8.5em',
    },
    dialogImg: {
        opacity: 0,
        height: '100%',
    },
    dialogContent: {
        position: 'absolute',
        left: `${16/13}em`,
        top: `${20/13}em`,
        width: `41.5em`,
        fontSize: `${13/16}em`,
        letterSpacing: -0.2,
        lineHeight: 24/13,
        fontWeight: 600,
        color: '#FFFFFF',
    },
    sector: {
        padding: '0 80px 80px',
        backgroundColor: '#FFFFFF',
        color: '#004FC5',
        zIndex: 3,
    },
    sectorTitle: {
        marginTop: '5em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    sectorTitleEn: {
        fontSize: '3.475em',
    },
    sectorTitleZh: {
        fontSize: '3.12em',
        fontWeight: 600,
        letterSpacing: 0,
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: `${60/16}em`,
        marginBottom: `${-80/16}em`,
        marginRight: `${-60/16}em`,
        justifyContent: 'space-between',
    },
    profile: {
        width: `${280/16}em`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: `${80/16}em`,
    },
    avatar: {
        width: `${200/16}em`,
        height: `${200/16}em`,
        objectFit: 'contain',
        marginBottom: `${13/16}em`,
    },
    name: {
        fontSize: '1.3125em',
        lineHeight: 1.4,
        fontWeight: 600,
    },
    info: {
        marginTop: `${5/13}em`,
        fontSize: '0.8125em',
        lineHeight: 1.6,
        fontWeight: 500,
        letterSpacing: -0.4,
        width: `${280/13}em`,
    },
});

function About() {
    const classes = useStyles();
    return (
        <Auftauchung><Page>
            <Auftauchen i={0}><div className={classes.title}>ABOUT US</div></Auftauchen>
            <Auftauchen i={1}><div className={classes.subtitle}>Artist & Engineer</div></Auftauchen>
            <Auftauchen i={2} className={classes.photo}>
                <Bild x1={back} x2={back2x} tiny={backTiny}/>
                <div className={classes.dialog}>
                    <img className={classes.dialogImg} src={dialog} alt=""/>
                    <Typography classes={{ root: classes.dialogContent }}>
                        人人都可玩转音乐。用音乐创造美好，用音乐连接世界，用音乐沟通彼此，用音乐触达心灵。<br/>
                        而我们为此创造产品，研发技术，让玩转音乐不仅仅只是艺术家们的事情。<br/>
                        我们拥抱 AI，迎接技术革命。我们推动「算法×艺术」的融会贯通。<br/>
                        Empower music with AI，用技术赋能每个人的音乐创造力。<br/>
                        我们是一个由音乐人，工程师，以及设计师组成的团队。我们来自浙江大学计算机学院、微软亚研院、阿里巴巴等，专业涵盖工程开发、算法开发、交互设计、音乐制作等多个领域，研发了 StepBeats、MadDelta、Skyful Calm 等多款 AI 音乐产品，联合成立了「浙江大学 Next Lab x 不亦乐乎人工智能联合实验室」。<br/>
                        加入我们，在 AI 音乐的广阔未来劈波斩浪。<br/>
                        有朋自远方来， __________ ?
                    </Typography>
                </div>
            </Auftauchen>
            <Auftauchen i={3} className={classes.sector}>
                <div className={classes.sectorTitle}>
                    <span className={classes.sectorTitleEn}>COUNSELOR&nbsp;</span>
                    <Typography component="span" classes={{ root: classes.sectorTitleZh }} color="inherit">/顾问</Typography>
                </div>
                <div className={classes.grid}>
                    <div className={classes.profile}>
                        <img className={classes.avatar} srcSet={`${wfr} 1x, ${wfr2x} 2x`} alt="Avatar"/>
                        <Typography classes={{ root: classes.name }} color="inherit">吴 斐然</Typography>
                        <Typography classes={{ root: classes.info }} color="inherit">
                            阿里数据中台技术负责人<br/>
                            浙江大学计算机科学博⼠ / 音乐制作⼈
                        </Typography>
                    </div>
                    <div className={classes.profile}>
                        <img className={classes.avatar} srcSet={`${tx} 1x, ${tx2x} 2x`} alt="Avatar"/>
                        <Typography classes={{ root: classes.name }} color="inherit">谭 旭</Typography>
                        <Typography classes={{ root: classes.info }} color="inherit">
                            微软亚洲研究院机器学习组高级研究员<br/>
                            在国际顶尖人工智能会议发表数十项研究成果
                        </Typography>
                    </div>
                    <div className={classes.profile}>
                        <img className={classes.avatar} srcSet={`${_3asic} 1x, ${_3asic2x} 2x`} alt="Avatar"/>
                        <Typography classes={{ root: classes.name }} color="inherit">3asic</Typography>
                        <Typography classes={{ root: classes.info }} color="inherit">
                            NI 产品专家 / 同步计划电子音乐厂牌主理人<br/>
                            国内低音音乐与电子舞曲代表人物之一
                        </Typography>
                    </div>
                    <div className={classes.profile}>
                        <img className={classes.avatar} srcSet={`${hcz} 1x, ${hcz2x} 2x`} alt="Avatar"/>
                        <Typography classes={{ root: classes.name }} color="inherit">侯 晨钟</Typography>
                        <Typography classes={{ root: classes.info }} color="inherit">
                            索尼游戏音频设计师 / 科技音乐家<br/>
                            资深合成器、音频 DSP 系统开发者
                        </Typography>
                    </div>
                    <div className={classes.profile}>
                        <img className={classes.avatar} srcSet={`${wxy} 1x, ${wxy2x} 2x`} alt="Avatar"/>
                        <Typography classes={{ root: classes.name }} color="inherit">王 小毅</Typography>
                        <Typography classes={{ root: classes.info }} color="inherit">
                            浙大管理学院市场营销系副教授 / 博导<br/>
                            浙江大学管理学院 MBA 中心主任
                        </Typography>
                    </div>
                    <div className={classes.profile}>
                        <img className={classes.avatar} src={ellipsis} alt="Avatar"/>
                    </div>
                </div>
            </Auftauchen>
        </Page></Auftauchung>
    );
}

export default About;
