import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { Lights } from 'Lights';

import App from 'App';
import * as serviceWorker from './serviceWorker';

import './fonts.css';

const history = createBrowserHistory();

ReactDOM.render((
    <MuiThemeProvider theme={theme}>
        <CssBaseline>
            <Router history={history}>
                <Lights>
                    <App/>
                </Lights>
            </Router>
        </CssBaseline>
    </MuiThemeProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
