import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Auftauchen } from 'Auftauchen';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    auftauchen: {
        zIndex: 3,
    },
    root: {
        position: 'relative',
        margin: `${17/16}em 0`,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        textAlign: 'center',
        height: `${646/16}em`,
    },
    link: {
        textDecoration: 'none',
    },
});

function Slide({ children, href, order, ...props }) {
    const classes = useStyles(props);
    return (
        <Auftauchen i={order} className={classes.auftauchen}>
            {(href && href.startsWith('/')) ? (
                <Link to={href} className={classes.link}>
                    <div className={classes.root}>
                        {children}
                    </div>
                </Link>
            ) : (
                <a href={href} className={classes.link} target="_blank" rel="noopener noreferrer">
                    <div className={classes.root}>
                        {children}
                    </div>
                </a>
            )}
        </Auftauchen>
    );
}

export default Slide;
