import React from 'react';
import { makeStyles } from '@material-ui/core';
import illustration from 'assets/images/illustration.png';
import illustration2x from 'assets/images/illustration@2x.png';
import em from 'assets/images/EM.svg';

const useStyles = makeStyles({
    illustration: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '51%',
    },
    emContainer: {
        position: 'fixed',
        right: 0,
        top: 0,
        width: '70vw',
        height: '100vh',
    },
    em: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    bar: {
        position: 'absolute',
        left: 12,
        top: 0,
        bottom: 0,
        backgroundColor: '#0045AC',
        width: 114,
    },
    barContainer: {
        position: 'absolute',
        width: '80%',
        top: 0,
        right: 0,
        bottom: 0,
    },
});

function Hand() {
    const classes = useStyles();
    return <img className={classes.illustration} srcSet={`${illustration} 1x, ${illustration2x} 2x`} alt=""/>;
}

function EM() {
    const classes = useStyles();
    return (
        <div className={classes.emContainer}>
            <img className={classes.em} src={em} alt=""/>
        </div>
    );
}

function Bar() {
    const classes = useStyles();
    return (
        <div className={classes.barContainer}>
            <div className={classes.bar}/>
        </div>
    );
}

export { Hand, EM, Bar };
