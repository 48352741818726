import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import location from 'assets/icons/location-gray-2.svg';
import locationBlue from 'assets/icons/location-blue-2.svg';
import { useLights } from 'Lights';
import classNames from 'classnames';
import { Auftauchen } from 'Auftauchen';

const useStyles = makeStyles({
    root: {
        display: 'block',
        position: 'absolute',
        left: 42,
        bottom: 50,
        color: '#FFFFFF',
        letterSpacing: 2,
        fontSize: '13px',
        lineHeight: '32px',
        verticalAlign: 'middle',
        marginBottom: -16,
        '&$lightsOn': {
            color: '#004FC5',
        },
        zIndex: 2,
    },
    location: {
        height: 21,
    },
    link: {
        color: 'inherit',
    },
    lightsOn: {},
});

function Footer() {
    const classes = useStyles();
    const { lights } = useLights();
    return (
        <Typography classes={{ root: classNames(classes.root, { [classes.lightsOn]: lights === 'on' }) }}>
            <Auftauchen i={3}>
                <img src={{ off: location, on: locationBlue }[lights]} alt=""/><br/>
                杭州市天时科创园 3-218<br/>
                (+86) 17857416782<br/>
                <a href="mailto:public@stepbeats.cn" className={classes.link}>public@enjoymusic.ai</a>
            </Auftauchen>
        </Typography>
    );
}

export default Footer;
