import Products from 'pages/Products';
import Timeline from 'pages/Timeline';
import Joinus from 'pages/Joinus';
import SAIR from 'pages/SAIR';
import About from 'pages/About';
import Sky from 'pages/Sky';
import Wanyin from 'pages/Wanyin';
import Policy from 'pages/Policy';
import { Bar, EM, Hand } from 'Backgrounds';

const routes = [
    {
        path: '/',
        component: Products,
        exact: true,
        lights: 'off',
        bgs: [Hand],
    },
    {
        path: '/products',
        component: Products,
        lights: 'off',
        bgs: [Hand],
    },
    {
        path: '/timeline',
        component: Timeline,
        lights: 'off',
        bgs: [Hand, Bar],
    },
    {
        path: '/joinus',
        component: Joinus,
        lights: 'off',
        bgs: [Hand],
    },
    {
        path: '/sair',
        component: SAIR,
        lights: 'on',
        bgs: [EM],
    },
    {
        path: '/about',
        component: About,
        lights: 'off',
        bgs: [Hand],
    },
    {
        path: '/sky',
        component: Sky,
        lights: 'half',
    },
    {
        path: '/wanyin',
        component: Wanyin,
        lights: 'redHalf',
    },
    {
        path: '/wanyin_privacy_policy',
        component: Policy,
        lights: 'redHalf',
    }
];

export default routes;
