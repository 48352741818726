import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { makeStyles, RootRef } from '@material-ui/core';
import classNames from 'classnames';
import './auftauchen.css';

const AuftauchenContext = React.createContext(null);

function Auftauchung({
    delay = 0,
    interval = 150,
    duration = 350,
    easing = 'cubic-bezier(0, 0.2, 0.4, 1)',
    children,
} = {}) {
    const auftauchen = useCallback(order =>
            `auftauchen ${duration}ms ${easing} ${delay + interval * order}ms 1 both`,
        [delay, duration, easing, interval]);
    return (
        <AuftauchenContext.Provider value={auftauchen}>
            {children}
        </AuftauchenContext.Provider>
    )
}

const useStyles = makeStyles({
    root: {
        opacity: 0,
    },
});

function Auftauchen({ children, i: order, className, ...rest }) {
    const auftauchen = useContext(AuftauchenContext);
    const ref = useRef();
    useEffect(() => {
        const el = ref.current;
        if (!el) return;
        el.style.animation = auftauchen(order);
    }, [auftauchen, order]);
    const classes = useStyles();
    return (
        <RootRef rootRef={ref}>
            <div className={classNames(classes.root, className)} {...rest}>
                {children}
            </div>
        </RootRef>
    );
}

export { Auftauchung, Auftauchen };
