import { Auftauchung } from 'Auftauchen';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import { responsiveFont1 } from 'utils/styles';

// eslint-disable-next-line import/no-webpack-loader-syntax
import terms2 from '!html-loader!markdown-loader!assets/misc/terms2.md';
const useStylesTerms = makeStyles({
    root: {
        ...responsiveFont1(),
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: '10.25em 0 4.5em',
    },
    content: {
        width: '72.8em',
        backgroundColor: '#FFFFFF',
        position: 'relative',
    },
    title: {
        position: 'absolute',
        left: '1.125em',
        top: '0.9375em',
        fontSize: '2em',
        lineHeight: 2.25,
        fontWeight: 600,
        color: '#000000',
    },
    text: {
        position: 'absolute',
        left: '2.25em',
        top: '6.8em',
        right: '2.25em',
        bottom: '6.8em',
        backgroundColor: '#F7F7F7',
        color: '#9B9B9B',
        padding: '2.2em 1.44em',
        overflow: 'auto',
    },
    button1: {
        position: 'absolute',
        bottom: '1.8em',
        right: '11.7em',
    },
    button2: {
        position: 'absolute',
        bottom: '1.8em',
        right: '2.25em',
        backgroundColor: '#F46173',
        color: '#FFFFFF',
    },
    circleClose: {
        position: 'absolute',
        width: 20,
        height: 20,
        top: '1em',
        right: '1em',
        cursor: 'pointer'
    }
});
function Policy (){
    const termsClasses = useStylesTerms();
          return( <Auftauchung>
                     <div 
                            className={termsClasses.text} 
                           dangerouslySetInnerHTML={{__html: terms2}}/>
                  </Auftauchung>)
}
  export default Policy;