import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        overflow: 'hidden',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    img: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transform: 'scale(1.05)'
    },
    alt: {
        opacity: 1,
        transition: 'opacity 250ms ease',
        filter: 'blur(10px)',
    },
    hidden: {
        opacity: 0,
    },
});

function Bild({ x1, x2, tiny, alt }) {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const onLoad = useCallback(() => setLoaded(true), []);
    return <div className={classes.root}>
        <img srcSet={`${x1} 1x, ${x2} 2x`} className={classes.img} alt={alt} onLoad={onLoad}/>
        <img src={tiny} className={classNames(classes.img, classes.alt, { [classes.hidden]: loaded })} alt={alt}/>
    </div>
}

export default Bild;
