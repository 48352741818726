import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';

const useStylesBlock = makeStyles({
    root: {
        marginTop: '3.8125em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    name: {
        fontSize: `${34/16}em`,
        lineHeight: 1.235,
        letterSpacing: 1,
        width: `${126/34}em`,
        whiteSpace: 'pre',
    },
    block: {
        backgroundColor: '#FFFFFF',
        paddingTop: '1em',
        paddingBottom: '1em',
        paddingLeft: '1.5em',
        marginLeft: `${46/16}em`,
        marginTop: `${6/16}em`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        color: '#004FC5',
    },
    title: {
        marginTop: `${8/21}em`,
        fontWeight: 600,
        letterSpacing: 0,
        fontSize: `${21/16}em`,
        lineHeight: 1,
    },
    content: {
        marginLeft: `${40/13}em`,
        fontWeight: 600,
        fontSize: `${13/16}em`,
        lineHeight: 34/13,
        letterSpacing: 0,
    },
});

function Block({ name, title, children, padding, ...props }) {
    const classes = useStylesBlock(props);
    return (
        <div className={classes.root}>
            <div className={classes.name}>{name}</div>
            <div className={classes.block} style={{ paddingRight: padding }}>
                <Typography classes={{ root: classes.title }}>{title}</Typography>
                <Typography classes={{ root: classes.content }}>{children}</Typography>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    root: {},
    bar: {
        position: 'absolute',
        left: 12,
        top: 0,
        bottom: 0,
        backgroundColor: '#0045AC',
        width: 114,
    },
    scroller: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
    },
    pages: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 'calc(45vh - 263px)',
        paddingLeft: 54,
        paddingBottom: 65,
    },
    timeline: {
        fontSize: '3.4375em',
    },
    ourStory: {
        fontSize: '2.125em',
        lineHeight: 1,
        fontWeight: 600,
        letterSpacing: 0,
    },
    future: {
        marginTop: 89,
    },
});

function Timeline() {
    const classes = useStyles();
    return (
        <Auftauchung><Page bg={<div className={classes.bar}/>}>
            <Auftauchen i={0}><div className={classes.timeline}>TIMELINE</div></Auftauchen>
            <Auftauchen i={0}><Typography classes={{ root: classes.ourStory }} color="inherit">我们的故事…</Typography></Auftauchen>
            <Auftauchen i={1}><Block name={"2017.\n09 - 10"} title="原点" padding={64}>
                在浙江大学紫金港校区的一个社团工作室里，我们的第一款产品 StepBeats 诞生了<br/>
                2017.09，获得第三次全国“互联网+”大学生创新创业大赛全国总决赛金奖<br/>
                2017.10，获中国高校计算机大赛移动应用创新赛全国总决赛一等奖等奖
            </Block></Auftauchen>
            <Auftauchen i={2}><Block name={"2018.\n01 - 05"} title="初创" padding={36}>
                2018.01，不亦乐乎科技（杭州）有限责任公司成立<br/>
                2018.05，获得天使轮 150W 投资
            </Block></Auftauchen>
            <Auftauchen i={3}><Block name={"2018.\n06 - 12"} title="前行" padding={90}>
                2018.06，团队登上 WWDC，苹果全球开发者大会的舞台<br/>
                2018.10，获得2018年“创青春”浙大双创杯全国大学生创业大赛国际名校邀请赛金奖<br/>
                2018.11，获得2018年“创青春”浙大双创杯全国大学生创业大赛第十一届“挑战杯”大学生创业计划竞赛金奖<br/>
                2018.12，获得全球青年创新创业大赛亚军<br/>
                2018.12，StepBeats 获得 App Store Today 版块推荐
            </Block></Auftauchen>
            <Auftauchen i={4}><Block name={"2019.\n01 - 06"} title="发展" padding={24}>
                2019.04，面向B端的产品 MadDelta 上线<br/>
                2019.06，StepBeats 获得豌豆荚最佳设计奖<br/>
                2019.07，StepBeats 获得小米应用商店金米奖<br/>
                2019.06，浙江大学 Next Lab x 不亦乐乎科技人工智能联合实验室成立
            </Block></Auftauchen>
            <Auftauchen i={5}><Block classes={{ root: classes.future }} name="Future" title="等你加入，一同书写" padding={29}/></Auftauchen>
        </Page></Auftauchung>
    );
}

export default Timeline;
