import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import photo from 'assets/images/WechatIMG3842.jpg';
import photo2x from 'assets/images/WechatIMG3842@2x.jpg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import photoTiny from '!!url-loader!assets/images/WechatIMG3842-tiny.png';
import culture from 'assets/images/culture.svg';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';
import Bild from 'Bild';

const useStyles = makeStyles({
    title: {
        fontSize: '3.4375em',
    },
    subtitle: {
        fontSize: '2.125em',
        marginBottom: '1em',
    },
    photo: {
        position: 'relative',
        height: '36.375em',
        zIndex: 3,
    },
    photoDesc: {
        position: 'absolute',
        left: '3.125em',
        bottom: '3.125em',
    },
    photoDescSubtitle: {
        fontSize: '2.125em',
        lineHeight: 1.6,
        letterSpacing: 0,
        fontWeight: 600,
    },
    sector: {
        padding: '0 50px 80px',
        backgroundColor: '#FFFFFF',
        color: '#004FC5',
        zIndex: 3,
    },
    sectorTitle: {
        marginTop: '3.75em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    sectorTitleEn: {
        fontSize: '2.125em',
    },
    sectorTitleZh: {
        fontSize: '2em',
        fontWeight: 600,
        letterSpacing: 0,
    },
    culture: {
        marginTop: '0.75em',
        width: '100%',
        objectFit: 'cover',
        marginBottom: '1.25em',
    },
    desc: {
        fontWeight: 500,
        letterSpacing: -0.2,
    },
    href: {
        color: '#FFBFE6',
    }
});

function Joinus() {
    const classes = useStyles();
    return (
        <Auftauchung><Page>
            <Auftauchen i={0}><div className={classes.title}>JOIN US</div></Auftauchen>
            <Auftauchen i={1}><div className={classes.subtitle}>F_ture, all we need is “U”</div></Auftauchen>
            <Auftauchen i={2} className={classes.photo}>
                <Bild x1={photo} x2={photo2x} tiny={photoTiny}/>
                <div className={classes.photoDesc}>
                    <div className={classes.title}>Empower music with AI</div>
                    <Typography classes={{ root: classes.photoDescSubtitle }} color="inherit">愿景和使命：音乐无门槛</Typography>
                </div>
            </Auftauchen>
            <Auftauchen i={3} className={classes.sector}>
                <div className={classes.sectorTitle}>
                    <span className={classes.sectorTitleEn}>CULTURE&nbsp;</span>
                    <Typography component="span" classes={{ root: classes.sectorTitleZh }} color="inherit">/文化</Typography>
                </div>
                <img className={classes.culture} src={culture} alt=""/>
                <div className={classes.sectorTitle}>
                    <span className={classes.sectorTitleEn}>JOIN US&nbsp;</span>
                    <Typography component="span" classes={{ root: classes.sectorTitleZh }} color="inherit">/加入我们</Typography>
                </div>
                <div className={classes.subtitle}>F_ture, all we need is “U”</div>
                <Typography classes={{ root: classes.desc }} color="inherit">
                    Hello，如果你<br/>
                    对我们的使命和愿景，对我们所坚持、热爱的事业感到认同，<br/>
                    欢迎将简历发送至 <a href="mailto:hr@stepbeats.cn" className={classes.href}>hr@stepbeats.cn</a>，<br/>
                    我们期待优秀的你作为<br/>
                    音乐人 / 研究员 / 算法工程师 / 开发工程师 加入EnjoyMusic的团队！
                </Typography>
            </Auftauchen>
        </Page></Auftauchung>
    );
}

export default Joinus;
