import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import sky from 'assets/icons/sky.svg';
import skyBack from 'assets/images/sky-back.jpg';
import skyBack2x from 'assets/images/sky-back@2x.jpg';
import wanyin from 'assets/icons/wanyin.png';
import wanyinBack from 'assets/images/wanyin-back.png';
import wanyinBack2x from 'assets/images/wanyin-back.png';
import wanyinTitle from 'assets/icons/wanyin-title.svg';
import maddelta from 'assets/icons/maddelta.svg';
import maddeltaBack from 'assets/images/maddelta-back.jpg';
import maddeltaBack2x from 'assets/images/maddelta-back@2x.jpg';
import stepbeats from 'assets/icons/stepbeats.svg';
import stepbeatsBack from 'assets/images/stepbeats-back.jpg';
import stepbeatsBack2x from 'assets/images/stepbeats-back@2x.jpg';
import up from 'assets/icons/up.svg';

import Slide from 'pages/Products/Slide';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';

const useT = (time) => {
    const [t, setT] = useState(0);
    const fire = useCallback((time) => {
        const startTime = performance.now();
        let cancelled = false;
        const update = (timestamp) => {
            const newT = (timestamp - startTime) / time;
            setT(newT > 1 ? 1 : newT);
            if (!cancelled && newT < 1) requestAnimationFrame(update);
        };
        requestAnimationFrame(update);
        return () => { cancelled = true };
    }, []);
    useEffect(() => fire(time), [fire, time]);
    return t;
};

function Typewriter({ children }) {
    const t = useT(2000);
    const text = children.toString();
    const len = Math.round(text.length * t);
    // noinspection JSConstructorReturnsPrimitive
    return len === text.length ? text : `${text.substring(0, len)}_` ;
}

const useStyles = makeStyles({
    page1: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        background: 'transparent',
        zIndex: 'auto',
        height: 'calc(55vh + 88px)',
    },
    text1: {
        marginTop: 77, // alignment, not em-based
        color: '#FFFFFF',
        fontSize: '4.25em',
        textAlign: 'left',
        width: '8em',
        height: '3.2em',
    },
    page2: {
        backgroundColor: '#FFFFFF',
    },
    text2: {
        color: '#004FC5',
        fontSize: '3.4375em',
    },
    arrow: {
        width: '1.5em',
        height: '1.5em',
        borderStyle: 'solid',
        borderColor: 'transparent #004FC5 #004FC5 transparent',
        borderWidth: 2,
        transform: 'rotate(45deg)',
        position: 'absolute',
        bottom: '1em',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    page3: {
        backgroundSize: 'cover',
        backgroundImage: `url(${skyBack})`,
        '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
            backgroundImage: `url(${skyBack2x})`,
        },
    },
    icon3: {
        width: '9.2em',
        marginBottom: '2em',
    },
    text3: {
        color: '#FFFFFF',
        fontSize: '3.4375em',
    },
    page4: {
        backgroundSize: 'cover',
        backgroundImage: `url(${maddeltaBack})`,
        '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
            backgroundImage: `url(${maddeltaBack2x})`,
        },
    },
    icon4: {
        height: '5.5625em',
        marginBottom: '2em',
    },
    text4: {
        color: '#004FC5',
        fontSize: '3.4375em',
    },
    page5: {
        backgroundSize: 'cover',
        backgroundImage: `url(${stepbeatsBack})`,
        '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
            backgroundImage: `url(${stepbeatsBack2x})`,
        },
    },
    icon5: {
        width: '9.2em',
        marginBottom: '2em',
    },
    text5: {
        color: '#FFFFFF',
        fontSize: '3.4375em',
    },
    up: {
        height: `${34/16}em`,
        marginTop: `${34/16}em`,
        alignSelf: 'flex-end',
        cursor: 'pointer',
    },
    page6: {
        backgroundSize: 'cover',
        backgroundImage: `url(${wanyinBack})`,
        '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
            backgroundImage: `url(${wanyinBack2x})`,
        },
    },
    icon6: {
        width: '9.2em',
        marginBottom: '2em',
    },
    text6: {
        color: '#FFFFFF',
        fontSize: '3.4375em',
    },
});

function Products() {
    const classes = useStyles();
    const [scroller, setScroller] = useState(null);
    const scrollToTop = useCallback(() => scroller && scroller.scrollTo({
        top: 0, left: 0, behavior: 'smooth',
    }), [scroller]);
    return (
        <Auftauchung><Page scrollerRef={setScroller}>
            <Slide classes={{ root: classes.page1 }} order={0}>
                <div className={classes.text1}>
                    <Typewriter>Empower music with AI.</Typewriter>
                </div>
            </Slide>
            <Slide classes={{ root: classes.page2 }} order={1}>
                <div className={classes.text2}>
                    We are pursuing<br/><br/>
                    <del>What the music used to be</del>
                    <br/>
                    What the music will be
                </div>
                <div className={classes.arrow}/>
            </Slide>
            <Slide classes={{ root: classes.page6 }} href="/wanyin" order={5}>
                <img className={classes.icon6} src={wanyin} alt="wanyin App Icon"/>
                <img className src={wanyinTitle} alt="wanyinTitle"/>
            </Slide>
            <Slide classes={{ root: classes.page3 }} href="/sky" order={2}>
                <img className={classes.icon3} src={sky} alt="Sky App Icon"/>
                <div className={classes.text3}>
                    Skyful<br/>
                    Smooth anxiety with music
                </div>
            </Slide>
            <Slide classes={{ root: classes.page4 }} href="https://maddelta.com/" order={3}>
                <img className={classes.icon4} src={maddelta} alt="MadDelta Icon"/>
                <div className={classes.text4}>
                    MadDelta<br/>
                    Endless music by AI
                </div>
            </Slide>
            <Slide classes={{ root: classes.page5 }} href="https://stepbeats.io/desktop/index-cn.html" order={4}>
                <img className={classes.icon5} src={stepbeats} alt="StepBeats Icon"/>
                <div className={classes.text5}>
                    StepBeats<br/>
                    Run to create music
                </div>
            </Slide>
            <Auftauchen i={5} className={classes.up}>
                <img src={up} alt="Scroll to top" onClick={scrollToTop}/>
            </Auftauchen>
        </Page></Auftauchung>
    );
}

export default Products;
