/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Page from 'Page';
import { Auftauchen, Auftauchung } from 'Auftauchen';
import birds from 'assets/images/birds.svg';
import appStore from 'assets/images/app_store.png';
import appStore2x from 'assets/images/app_store@2x.png';
import googlePlay from 'assets/images/google_play.png';
import googlePlay2x from 'assets/images/google_play@2x.png';
import skyApp from 'assets/images/sky-app.png';
import skyApp2x from 'assets/images/sky-app@2x.png';

const useStyles = makeStyles({
    content: {
        height: '100%',
        background: 'linear-gradient(18deg, #D7E7FF 0%, #91AFE4 71%)',
        position: 'relative',
    },
    title: {
        position: 'absolute',
        left: '1.1765em',
        top: 'calc(-100px + 45.64%)',
        fontSize: '4.25em',
        lineHeight: 1.27,
    },
    subtitle: {
        fontSize: '2.125em',
        fontWeight: 600,
        letterSpacing: 0,
        marginTop: '0.4em',
    },
    desc: {
        position: 'absolute',
        left: '6.154em',
        top: 'calc(-100px + 45.64% + 13.7em)',
        width: '41em',
        fontSize: '0.8125em',
        fontWeight: 'normal',
        letterSpacing: 0.14,
        textAlign: 'justify',
        lineHeight: 1.6,
    },
    pages: {
        display: 'block',
        paddingBottom: 0,
        height: '100%',
        marginLeft: -12,
        paddingTop: 120,
    },
    birds: {
        position: 'absolute',
        left: '5em',
        top: '7.05%',
        width: '17.5em',
    },
    downloads: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        left: '5em',
        top: '72.18%',
    },
    badge: {
        display: 'block',
        marginRight: 20,
        height: 40,
    },
    disabled: {
        cursor: 'not-allowed',
    },
    links: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        left: '5em',
        color: '#FFFFFF',
        bottom: '5.13%',
    },
    link: {
        fontSize: '0.8125em',
        fontWeight: 'normal',
        letterSpacing: 1,
        lineHeight: 1.85,
    },
    a: {
        color: 'inherit',
        display: 'block',
        marginRight: '2em',
    },
    app: {
        position: 'absolute',
        right: '4.375em',
        top: '7.5625em',
        bottom: 0,
        height: 'calc(100% - 7.5625em)'
    },
});

function SAIR() {
    const classes = useStyles();
    return (
        <Auftauchung><Page wide classes={{ pages: classes.pages }}>
            <Auftauchen i={2} className={classes.content}>
                <img src={birds} className={classes.birds} alt=""/>
                <div className={classes.title}>
                    Skyful: Smooth<br/>Anxiety with music.
                </div>
                <Typography classes={{ root: classes.desc }} color="inherit">
                    Skyful 是一款极简设计的健康应用，旨在减轻人们的压力和焦虑。 利用AI作曲技术，我们创造了无尽的旋律，帮助人们保持专注，冥想和轻松入睡。 没有任何人声和其他复杂的功能，唯一要做的就是进入空间，享受音乐。
                </Typography>
                <div className={classes.downloads}>
                    <a
                        href="https://apps.apple.com/cn/app/sky-calm-relaxing-music/id1481359708"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.badge}
                    >
                        <img srcSet={`${appStore} 1x, ${appStore2x} 2x`} alt="Download on App Store"/>
                    </a>
                    <img
                        className={classNames(classes.badge, classes.disabled)}
                         srcSet={`${googlePlay} 1x, ${googlePlay2x} 2x`}
                        alt="Download on Google Play"
                    />
                </div>
                <div className={classes.links}>
                    <a href="#" className={classes.a}>
                        <Typography classes={{ root: classes.link }}>服务条款</Typography>
                    </a>
                    <a href="#" className={classes.a}>
                        <Typography classes={{ root: classes.link }}>隐私政策</Typography>
                    </a>
                </div>
                <img srcSet={`${skyApp} 1x, ${skyApp2x} 2x`} className={classes.app} alt=""/>
            </Auftauchen>
        </Page></Auftauchung>
    );
}

export default SAIR;
