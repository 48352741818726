import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { matchRoutes, renderRoutes } from 'react-router-config';
import useRouter from 'use-react-router';

import Contacts from 'Contacts';
import Nav from 'Nav';
import Footer from 'Footer';
import routes from 'routes';
import { useLights } from 'Lights';

import logo from 'assets/icons/enjoymusic.svg';
import logoDark from 'assets/icons/enjoymusic-dark.svg';
import { Auftauchen, Auftauchung } from 'Auftauchen';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    container: {
        position: 'relative',
        maxWidth: 1440,
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logo: {
        position: 'absolute',
        left: 42,
        top: 30,
        height: 44,
    },
    logoContainer: {
        zIndex: 10,
        position: 'relative',
    },
});

function App() {
    const classes = useStyles();
    const { location: { pathname } } = useRouter();
    const [{ route: { lights = 'off', bgs = [] } = {} } = {}] = useMemo(() => matchRoutes(routes, pathname), [pathname]);
    const { setLights } = useLights();
    useEffect(() => setLights(lights), [lights, setLights]);
    return (
        <Auftauchung>
            <div className={classes.root} style={{ background: {
                off: '#004FC5',
                on: '#FFFFFF',
                half: 'linear-gradient(18deg, #D7E7FF 0%, #91AFE4 71%)',
                redHalf:'linear-gradient(1deg, #FFFFFF 0%, #FFB199 58%, #F03A3A 100%)'
            }[lights] }}>
                <div className={classes.container}>
                    <Auftauchen i={0} className={classes.logoContainer}><Link to="/">
                        <img className={classes.logo} src={{ off: logo, on: logoDark, half: logo, redHalf: logo }[lights]} alt="EnjoyMusic Logo"/>
                    </Link></Auftauchen>
                    {lights !== 'half' && lights !== 'redHalf' && <Nav/>}
                    {lights !== 'half' && lights !== 'redHalf' && <Footer/>}
                    <Contacts/>
                    {bgs.map((Element, n) => <Element key={n}/>)}
                </div>
                {renderRoutes(routes)}
            </div>
        </Auftauchung>
    );
}

export default App;
